import React, { useContext, useEffect, useState } from 'react';
import { SearchBar, SmartTileGrid } from '../../components/sections';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useAppInformationContent } from '../../components/sections/breadcrumbs/hook/use-breadcrumb-content';
import { useAnalytics } from '../../hooks/use-analytics';
import ServerContext from '../../contexts/serverContext';
import { FeaturedArticlesCarousel } from '../../components/sections/vehicle-portal/components/featured-articles-carousel/featured-articles-carousel';
import { ComponentBox5050 } from '../../components/sections/category/categories/component-box-5050/component-box-5050';
import { ASTUTE } from '../../support/support-constants';
import BrowseByTopic from '../../support/components/browse-by-topic/browse-by-topic';
import { SmashCard } from './components/smash-card/smash-card';
import { DisclaimerContent } from '../../services/disclaimers-service/disclaimers-service';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import { useProcessedHomeContent } from './hooks/use-processed-home-content';
import { FeatureTrio } from '../../components/sections/vehicle-portal/components/feature-trio/feature-trio';

import '../home-page/home-page.scss';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../page-not-found-view/hooks/use-help-cards-content';
import { Masthead } from '../../components/common/masthead/masthead';
import DisclosuresLinkSyndicationAware from '../../components/sections/disclosures-link/disclosures-link-syndication-aware';
import { LoginButton } from '../../components/sections/login-button/login-button';
import { useNotificationBannerContent } from '../../components/sections/notification-banner/hooks/use-notification-banner';
import NotificationBanner from '../../components/sections/notification-banner/notification-banner';
import {
    AuthenticationState,
    useAuthentication,
} from '../../hooks/use-authentication';
import { StringUtil } from '../../components/utils/string-util/string-util';
import { SecondaryButton } from '../../components/common';
import { USER_GREETING_DELAY_SECONDS } from '../../constants';
import SkinnyBanner from '../../components/common/skinny-banner/skinny-banner';
import { useSkinnyBannerContent } from '../../components/common/skinny-banner/hook/use-skinny-banner';
import { VehicleSelectorWrapper } from './components/vehicle-selector-wrapper/vehicle-selector-wrapper';
import { useExperienceContent } from '../../hooks/use-server-content';
import { TabsFragment } from '../../models/experiencefragments/tabs';
import { SmashFindASolutionWrapper } from '../../components/sections/smash-find-a-solution/smash-find-a-solution-wrapper';
import useSeoConfig from '../../hooks/use-seo-config';

export const HomePage = () => {
    const [showPersonalGreeting, setShowPersonalGreeting] = useState(false);
    const [email, setEmail] = useState<string | null>();
    const [fname, setFname] = useState<string | null>();
    const [authenticationState] = useAuthentication();

    let disclaimers: DisclaimerContent[] = [];
    useAnalytics(
        ['target-trigger-view', 'smash-owner-home'],
        'target-owner-home'
    );
    const {
        processedDisclaimers,
        processedHomeContent,
        processedFeaturedArticlesCarouselContent,
        processedSmashCard,
        processedBbtContent,
        processedSmartTilesContent,
        processedArticlesTrio1,
        processedComponentBox5050,
        processedArticlesTrio2,
    } = useProcessedHomeContent(disclaimers);
    const skinnyBannerContent = useSkinnyBannerContent(
        'landing',
        'skinny-banner',
        false
    );
    disclaimers = disclaimers.concat(processedDisclaimers);
    const appInformationContent = useAppInformationContent();
    const page = 'Landing';
    const { currentLanguageRegionCode } = useContext(ServerContext);
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();

    const showGreeting = (delay: string) => {
        const differenceInSeconds =
            (new Date().getTime() - Number.parseInt(delay)) / 1000;
        console.debug('differenceInSeconds is ', differenceInSeconds);
        if (differenceInSeconds > USER_GREETING_DELAY_SECONDS) return true;
        else return false;
    };

    const canShowGreeting = () => {
        let show = false;
        const ownLastLoggedInUser = localStorage.getItem('ownLastLoggedInUser');
        const ownUserFirstTimeLogin = localStorage.getItem(
            'ownUserFirstTimeLogin'
        );
        const userGreetingLastActionedAt = localStorage.getItem(
            'userGreetingLastActionedAt'
        );
        if (ownUserFirstTimeLogin) {
            show = showGreeting(ownUserFirstTimeLogin);
        } else if (ownLastLoggedInUser && userGreetingLastActionedAt) {
            show = showGreeting(userGreetingLastActionedAt);
        } else show = false;
        return show;
    };
    const category = 'landing';
    useSeoConfig('home');

    const [tabsContent] = useExperienceContent<TabsFragment>(
        category,
        'tabs',
        'tabs',
        undefined,
        false
    );

    useEffect(() => {
        if (
            canShowGreeting() &&
            authenticationState !== AuthenticationState.Authenticated
        ) {
            const ownLastLoggedInUser = localStorage.getItem(
                'ownLastLoggedInUser'
            );
            const ownLastLoggedInUserFname = localStorage.getItem(
                'ownLastLoggedInUserFname'
            );
            if (
                ownLastLoggedInUser &&
                ownLastLoggedInUserFname &&
                processedHomeContent?.userGreetingLine1 &&
                processedHomeContent?.userGreetingLine2 &&
                processedHomeContent?.userGreetingLine3
            ) {
                setEmail(ownLastLoggedInUser);
                setFname(ownLastLoggedInUserFname);
                setShowPersonalGreeting(true);
            }
        }
    }, [authenticationState]);

    const onGreetingAction = (action: 'Login' | 'Dismiss') => {
        if (showPersonalGreeting) {
            if (action === 'Dismiss') setShowPersonalGreeting(false);
            const nowEpoch = new Date().getTime();
            localStorage.setItem(
                'userGreetingLastActionedAt',
                nowEpoch.toString()
            );
            localStorage.removeItem('ownUserFirstTimeLogin');
        }
    };

    const maskedEmail = (email: string) => {
        const emailPrefix = email.split('@')[0];
        const emailSuffix = email.split('@')[1];
        const masked =
            emailPrefix.substring(0, 2) +
            emailPrefix.substring(2, emailPrefix.length).replace(/./g, '*');
        return masked + '@' + emailSuffix;
    };

    const personalizedHeadline = () => {
        if (email && fname && processedHomeContent?.userGreetingLine1) {
            return processedHomeContent.userGreetingLine1.replace(
                '[fname]',
                StringUtil.capitalizeFirstLetter(fname)
            );
        } else return processedHomeContent?.bannerTitle;
    };

    const personalizedDescription = () => {
        if (email && processedHomeContent?.userGreetingLine2)
            return processedHomeContent.userGreetingLine2.replace(
                '[email]',
                maskedEmail(email)
            );
        else return processedHomeContent?.bannerDescription;
    };

    const notificationBannerContent = useNotificationBannerContent(
        'landing',
        'notification-banner'
    );
    return (
        <>
            {processedHomeContent && appInformationContent ? (
                <div className="shared-view smash-home">
                    <div id="Hero">
                        <div>
                            {notificationBannerContent &&
                                notificationBannerContent.bannerText && (
                                    <NotificationBanner
                                        hide={notificationBannerContent?.hide}
                                        type={notificationBannerContent?.type}
                                        bannerDismissible={
                                            notificationBannerContent?.bannerDismissible
                                        }
                                        dismissAriaLabel={
                                            notificationBannerContent?.dismissAriaLabel
                                        }
                                        bannerText={
                                            notificationBannerContent?.bannerText
                                        }
                                        showChevron={
                                            notificationBannerContent?.showChevron
                                        }
                                        shortCode={{
                                            ctaType: 'landing banner cta',
                                        }}
                                        eventName={'landing-banner-cta'}
                                    />
                                )}
                        </div>
                        <Masthead
                            headline={
                                showPersonalGreeting
                                    ? personalizedHeadline()
                                    : processedHomeContent.bannerTitle
                            }
                            description={
                                showPersonalGreeting
                                    ? personalizedDescription()
                                    : processedHomeContent.bannerDescription
                            }
                            mobileImg={
                                processedHomeContent.homeBackgroundImageMobile ||
                                ''
                            }
                            desktopImg={
                                processedHomeContent.homeBackgroundImageDesktop ||
                                ''
                            }
                        >
                            <LoginButton
                                page="Landing"
                                fill="outline"
                                chevron={false}
                                dataTestId="homepage-login"
                                onClick={() => onGreetingAction('Login')}
                            />
                            {showPersonalGreeting &&
                                fname &&
                                processedHomeContent.userGreetingLine3 && (
                                    <SecondaryButton
                                        noChevron
                                        className="not-user-button"
                                        onClick={() =>
                                            onGreetingAction('Dismiss')
                                        }
                                    >
                                        {processedHomeContent.userGreetingLine3.replace(
                                            '[fname]',
                                            fname
                                        )}
                                    </SecondaryButton>
                                )}
                        </Masthead>
                    </div>
                    <div className={'find-a-solution-wrapper'}>
                        <SmashFindASolutionWrapper />
                    </div>
                    {tabsContent && (
                        <VehicleSelectorWrapper
                            title={tabsContent?.tabs?.map((tab, index) => {
                                if (index == 0) {
                                    return tab.title;
                                }
                            })}
                            page={page}
                        />
                    )}
                    {skinnyBannerContent && (
                        <SkinnyBanner {...skinnyBannerContent} />
                    )}
                    {processedFeaturedArticlesCarouselContent &&
                        !processedFeaturedArticlesCarouselContent.hide && (
                            <FeaturedArticlesCarousel
                                {...processedFeaturedArticlesCarouselContent}
                                analyticsClickEventName="smash-home-owning-ford-onclick-event"
                            />
                        )}
                    {ASTUTE.SUPPORTED_MARKETS.includes(
                        currentLanguageRegionCode
                    ) && (
                        <BrowseByTopic
                            bbtContent={processedBbtContent}
                            analyticsClickEventName="smash-home-bbt-category-onclick-event"
                        />
                    )}
                    {processedSmashCard && (
                        <SmashCard {...processedSmashCard} />
                    )}
                    {processedSmartTilesContent && (
                        <SmartTileGrid
                            page={page}
                            className={'information-grid'}
                            section={'information-grid'}
                            smartTileContent={processedSmartTilesContent}
                            analyticsEventName="smash-home-services-onclick-event"
                        />
                    )}
                    {processedArticlesTrio1 && (
                        <FeatureTrio
                            articles={processedArticlesTrio1}
                            cards={[]}
                            dynamicCardId="K"
                            analyticsEventName="smash-home-howto-videos-onclick-event"
                        />
                    )}
                    {processedComponentBox5050 && (
                        <ComponentBox5050
                            content={processedComponentBox5050}
                            {...processedComponentBox5050}
                        />
                    )}
                    {processedArticlesTrio2 && (
                        <FeatureTrio
                            page={page}
                            articles={processedArticlesTrio2}
                            cards={[]}
                            dynamicCardId="F"
                            analyticsEventName="smash-home-service-offers-onclick-event"
                        />
                    )}
                    {disclaimers.length > 0 && (
                        <DisclaimerList disclaimers={disclaimers} />
                    )}
                    {searchContent && helpCardsContent && (
                        <SearchBar
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                    <DisclosuresLinkSyndicationAware />
                </div>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
export default HomePage;
